<template>
  <div class="itemBox">
    <div class="l">
      <a href="javascript:;">
        <div
          :class="{
            act:
              $route.path == '/personal/myVideo' ||
              $route.path == '/personal/' ||
              $route.path == '/personal',
          }"
          class="item"
          @click="uti.goPath('/personal/myVideo')"
        >
          我的视频
        </div>
      </a>
      <a href="javascript:;">
        <div
          :class="{ act: $route.path == '/personal/subs' }"
          class="item"
          @click="uti.goPath('/personal/subs')"
        >
          我的订阅
        </div>
      </a>
      <a href="javascript:;">
        <div
          :class="{ act: $route.path == '/personal/collect' }"
          class="item"
          @click="uti.goPath('/personal/collect')"
        >
          我的收藏
        </div>
      </a>
      <a href="javascript:;">
        <div
          :class="{ act: $route.path == '/personal/follows' }"
          class="item"
          @click="uti.goPath('/personal/follows')"
        >
          我的粉丝
        </div>
      </a>
      <a href="javascript:;">
        <div
          :class="{ act: $route.path == '/personal/videoIncome' }"
          class="item"
          @click="uti.goPath('/personal/videoIncome')"
        >
          视频收入
        </div>
      </a>
    </div>
    <div class="r">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.store,
    };
  },
  methods: {
    goPath() {},
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  .l {
    /* 矩形 21 */

    width: 312px;
    height: 849px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    padding-top: 30px;
    .item {
      height: 55px;
      line-height: 55px;
      font-family: SourceHanSansCN-Medium;
      padding: 0 37px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0em;
      color: #333333;
    }
    .act {
      color: #ff7d41;
    }
  }
  .r {
    flex: 1;
    margin-left: 21px;
  }
}
</style>
