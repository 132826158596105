<template>
  <div class="itemBox">
    <div class="l">
      <div class="user">
        <img v-if="false" :src="store.avatar" alt="" />
        <!-- <div class="wl-avatar">
          {{ uti.setAvatar(store.user.nickName) }}
        </div> -->
        <avatar-item :size="80"></avatar-item>
        <div class="dec">
          <div class="name">{{ store.user.nickName }}</div>
          <div class="addr">{{ uti.shrink(store.user.address) }}</div>
        </div>
      </div>
      <div class="upName" @click="updeteName()">修改昵称</div>
    </div>
    <div class="r">
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-guanzhu"></use>
        </svg>
        <span>关注</span>
        <div class="num">{{ personalDec.followedNum }}</div>
      </div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-guanyuwomen"></use>
        </svg>
        <span>粉丝</span>
        <div class="num">{{ personalDec.fansNum }}</div>
      </div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-jifenduihuan"></use>
        </svg>
        <span>打赏收入</span>
        <div class="num">{{ personalDec.rewardsNum }}</div>
      </div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-dianzan"></use>
        </svg>
        <span>获赞</span>
        <div class="num">{{ personalDec.lovesNum }}</div>
      </div>
    </div>
    <up-name ref="upname"></up-name>
  </div>
</template>
<script>
import Store from "../../store";
import AvatarItem from "../avatar/avatar-item.vue";
import upName from "./upName.vue";
export default {
  components: { upName, AvatarItem },
  mounted() {
    this.getPersonalDec();
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,

      personalDec: {
        fansNum: 0, //粉丝	integer(int64)
        followedNum: 0, //关注	integer(int64)
        lovesNum: 0, //获赞	integer(int64)
        rewardsNum: 0, //打赏收入	integer(int64)
      },
    };
  },
  methods: {
    updeteName() {
      this.$refs.upname.up();
    },
    // 主页统计数据
    async getPersonalDec() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        `/userInfo/statistics/${this.store.user.userId}`
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.personalDec = res.data;
      this.store.personalDec = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  justify-content: space-between;
  .l {
    /* 矩形 8 */
    width: 312px;
    height: 223px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    padding: 20px 37px;
    box-sizing: border-box;
    .user {
      margin-top: 10px;
      display: flex;
      img {
        /* 矩形 18 */
        width: 80px;
        height: 80px;
        border-radius: 5px;
        background: #41b6ff;
      }
      .wl-avatar {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        background: #41b6ff;
      }
      .dec {
        padding-left: 13px;
        .name {
          height: 35px;
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #333333;
        }
        .addr {
          margin-top: 13px;
          height: 27px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
    }
    .upName {
      /* 矩形 19 */
      width: 233px;
      height: 46px;
      line-height: 46px;
      margin-top: 46px;
      text-align: center;
      border-radius: 5px;
      background: #ff7d41;
      /* 修改昵称 */
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
    }
  }
  .r {
    /* 矩形 20 */
    flex: 1;
    margin-left: 21px;
    height: 223px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    display: flex;
    .item {
      width: 25%;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg {
        font-size: 40px;
      }
      span {
        /* 粉丝 */
        margin-top: 8px;
        height: 26px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
      .num {
        margin-top: 22px;
        /* 211 */
        height: 43px;
        font-family: SourceHanSansCN-Bold;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0em;
        color: #333333;
      }
    }
  }
}
</style>
